var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "guest-pay" },
    [
      _c(
        "div",
        {
          staticClass:
            "nav-bar row w-100 d-flex flex-row-reverse align-items-center pr-3",
        },
        [
          _c(
            "a",
            {
              staticClass: "nav-link",
              attrs: {
                href: `${_vm.invoice?.data ? _vm.invoice.data.loginUrl : "/"}`,
                "aria-label": "Sign In Link",
              },
            },
            [
              _c("b-img", {
                staticClass: "mr-1",
                attrs: { src: "/images/payments/log-in.svg" },
              }),
              _vm._v("\n      Sign In\n    "),
            ],
            1
          ),
        ]
      ),
      !_vm.paymentConfirmed
        ? _c("div", [
            _c("div", { staticClass: "row w-100 m-0" }, [
              _c(
                "div",
                {
                  staticClass:
                    "col-lg-7 col-md-6 d-flex justify-content-center",
                },
                [
                  _c(
                    "div",
                    { staticClass: "payment-info-width" },
                    [
                      _c("h4", { staticClass: "font-weight-bold mt-5" }, [
                        _vm._v("Welcome to"),
                      ]),
                      _c("h2", { staticClass: "font-weight-bold mt-1" }, [
                        _vm._v(_vm._s(_vm.invoice.data.contact.name)),
                        _c("br"),
                        _vm._v("Quick Pay"),
                      ]),
                      _c("div", { staticClass: "mt-4" }, [
                        _vm._v(
                          "Enter your payment information to be used for invoice number "
                        ),
                        _c("b", [
                          _vm._v(_vm._s(_vm.invoice.data.invoiceNumber)),
                        ]),
                      ]),
                      _c(
                        "b-form",
                        { staticClass: "fields form-group" },
                        [
                          _c("validation-observer", {
                            ref: "observer",
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function ({ invalid }) {
                                    return [
                                      _c(
                                        "div",
                                        { staticClass: "row mb-0 pb-0" },
                                        [
                                          _c(
                                            "h6",
                                            {
                                              staticClass:
                                                "font-weight-bold pl-3 mt-2 mb-3",
                                            },
                                            [
                                              _vm._v(
                                                "\n                  Payment Information\n                "
                                              ),
                                            ]
                                          ),
                                          _c("credit-card-form", {
                                            on: {
                                              changed: _vm.cardInfoChanged,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "row" },
                                        [
                                          _c(
                                            "h6",
                                            {
                                              staticClass:
                                                "font-weight-bold pl-3 mt-0 mb-3",
                                            },
                                            [
                                              _vm._v(
                                                "\n                  Address Information\n                "
                                              ),
                                            ]
                                          ),
                                          _c("address-form", {
                                            attrs: {
                                              "allow-existing-addresses": false,
                                              "show-kind": false,
                                            },
                                            on: {
                                              changed: _vm.addressInfoChanged,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "row" },
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { cols: "12", md: "12" } },
                                            [
                                              _c(
                                                "h6",
                                                {
                                                  staticClass:
                                                    "font-weight-bold mb-3 mt-1",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                    An email receipt will go to the account's email.\n                    For a copy sent to another email, please specify below.\n                  "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    id: "email-address-group",
                                                    label:
                                                      "Additional Email Receipts",
                                                  },
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name: "email",
                                                      rules: "email",
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function ({
                                                            errors,
                                                          }) {
                                                            return [
                                                              _c(
                                                                "b-form-input",
                                                                {
                                                                  attrs: {
                                                                    id: "email-input-text",
                                                                    type: "text",
                                                                    state:
                                                                      !errors[0]
                                                                        ? null
                                                                        : true,
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm.emailInputText,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.emailInputText =
                                                                          $$v
                                                                      },
                                                                    expression:
                                                                      "emailInputText",
                                                                  },
                                                                }
                                                              ),
                                                              _c(
                                                                "b-form-invalid-feedback",
                                                                {
                                                                  attrs: {
                                                                    state:
                                                                      !errors[0],
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                        " +
                                                                      _vm._s(
                                                                        errors[0]
                                                                      ) +
                                                                      "\n                      "
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      true
                                                    ),
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-form-invalid-feedback",
                                        {
                                          attrs: { id: "input-live-feedback" },
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(_vm.errors) +
                                              "\n              "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              1985640055
                            ),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "col-lg-5 col-md-6 pt-5 justify-content-center bg-payment-summary",
                },
                [
                  _c(
                    "b-card",
                    {
                      staticClass: "p-1 mb-4 mx-auto payment-summary",
                      attrs: { id: "cart" },
                    },
                    [
                      _c("h4", { staticClass: "font-weight-bold mt-1 mb-4" }, [
                        _vm._v("\n            Payment Summary\n          "),
                      ]),
                      _c("b-card-text", [
                        _vm.invoiceError
                          ? _c("em", [
                              _vm._v(
                                "\n              There was an issue locating that invoice. Please login below or call us for more details regarding this invoice.\n            "
                              ),
                            ])
                          : !_vm.payableInvoice
                          ? _c("em", [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.notPayableMessage) +
                                  " Login below to view more details about invoice #" +
                                  _vm._s(_vm.invoice.data.invoiceNumber) +
                                  ".\n            "
                              ),
                            ])
                          : _vm._e(),
                        _vm.invoice && _vm.payableInvoice
                          ? _c(
                              "div",
                              { staticStyle: { "font-size": "0.9rem" } },
                              [
                                _c("p", [
                                  _vm._v("Invoice Number "),
                                  _c("b", [
                                    _vm._v(
                                      _vm._s(_vm.invoice.data.invoiceNumber)
                                    ),
                                  ]),
                                ]),
                                _vm.invoice.data.description
                                  ? _c("p", [
                                      _vm._v(
                                        "\n                Invoice Description: " +
                                          _vm._s(_vm.invoice.data.description) +
                                          "\n              "
                                      ),
                                    ])
                                  : _vm._e(),
                              ]
                            )
                          : _vm._e(),
                      ]),
                      _vm.invoice && _vm.payableInvoice
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "d-flex flex-row justify-content-between",
                            },
                            [
                              _c("div", [_c("strong", [_vm._v("Total")])]),
                              _c("div", [
                                _c("strong", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("currency")(_vm.invoice.data.total)
                                    )
                                  ),
                                ]),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      _c("hr"),
                      _vm.payableInvoice
                        ? _c(
                            "b-form-checkbox",
                            {
                              model: {
                                value: _vm.tosAccepted,
                                callback: function ($$v) {
                                  _vm.tosAccepted = $$v
                                },
                                expression: "tosAccepted",
                              },
                            },
                            [
                              _c("span", { staticClass: "small" }, [
                                _vm._v(
                                  "\n              By clicking 'Submit', I accept the "
                                ),
                                _c(
                                  "a",
                                  {
                                    staticClass: "link-primary",
                                    attrs: {
                                      href: _vm.newTosUrl,
                                      target: "_blank",
                                    },
                                  },
                                  [_vm._v("Terms and Conditions")]
                                ),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      _c(
                        "b-form-group",
                        {
                          staticClass:
                            "guest-submit-btn d-flex justify-content-center",
                        },
                        [
                          _vm.isBusy
                            ? _c("ct-centered-spinner", { staticClass: "pb-4" })
                            : _vm.payableInvoice
                            ? _c(
                                "b-button",
                                {
                                  staticClass: "btn-preferred",
                                  attrs: {
                                    disabled: _vm.isBusy || !_vm.tosAccepted,
                                  },
                                  on: { click: _vm.savePaymentInfo },
                                },
                                [
                                  _vm._v(
                                    "\n              Submit Payment\n            "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.paymentMethodErrors
                    ? _c(
                        "div",
                        { staticClass: "p-1 mb-4 mx-auto payment-failed" },
                        [
                          _c("banner", {
                            attrs: {
                              message: _vm.paymentMethodErrors,
                              variant: "failed",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("hr"),
                  _c("b-table", {
                    staticClass: "mx-auto w-75",
                    attrs: {
                      items: _vm.invoice.data.lineItems,
                      fields: _vm.fields,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "cell(description)",
                          fn: function (data) {
                            return [
                              _c("div", [
                                _vm._v(_vm._s(data.item.description)),
                              ]),
                              data.item.showItems
                                ? _c(
                                    "div",
                                    _vm._l(
                                      data.item.invoiceLineItems,
                                      function (item, index) {
                                        return _c(
                                          "div",
                                          { key: index, staticClass: "pl-2" },
                                          [
                                            _c("small", [
                                              _vm._v(
                                                "↳" + _vm._s(item.description)
                                              ),
                                            ]),
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  )
                                : _vm._e(),
                            ]
                          },
                        },
                        {
                          key: "cell(price)",
                          fn: function (data) {
                            return [
                              _vm._v(
                                _vm._s(_vm._f("currency")(data.item.price))
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      4057913963
                    ),
                  }),
                ],
                1
              ),
            ]),
          ])
        : _c("div", [
            _c("h1", { staticClass: "text-center" }, [
              _vm._v("\n      Payment Confirmed\n    "),
            ]),
            _c(
              "p",
              { staticClass: "text-center", staticStyle: { color: "#777" } },
              [
                _vm._v(
                  "\n      An email will be sent to the account " +
                    _vm._s(
                      _vm.emailInputText ? "and " + _vm.emailInputText : ""
                    ) +
                    " notifying your payment. "
                ),
                _c("br"),
                _vm._v("\n      Thank you for your business!\n    "),
              ]
            ),
            _c("hr"),
            _c("p", { staticClass: "text-center" }, [
              _vm._v("\n      You can\n      "),
              _c("a", { attrs: { href: `${_vm.invoice.data.loginUrl}` } }, [
                _vm._v("\n        login\n      "),
              ]),
              _vm._v("\n      or return to\n      "),
              _c(
                "a",
                { attrs: { href: `https://${_vm.invoice.data.contact.url}` } },
                [_vm._v(" " + _vm._s(_vm.invoice.data.contact.url))]
              ),
            ]),
          ]),
      _vm.invoice
        ? _c("guest-pay-confirmation-modal", {
            attrs: {
              invoice: _vm.invoice.data,
              "confirmation-number": _vm.confirmationNumber,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }